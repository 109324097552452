<script>
import { PortableText } from '@portabletext/svelte';
export let section;
</script>

<section class="monitoring-temp-section">
  <div class="monitoring-temp-section__inner">
    <div class="container-fluid">
      <div class="row  center-md">
        <div class="col-sm-12 col-lg-12">
          <div class="row center-md middle-md">
            <div class="col-sm-12 col-md-6">
              <div class="monitoring-temp-section__image">
                <img
                  srcset="
                    https://cdn.sanity.io/images/13m8empt/production/76b83dc3f18fa7bbcc5f55931a342a0698eb73e7-2000x2000.png?auto=format&w=1000 1000w,
                    https://cdn.sanity.io/images/13m8empt/production/76b83dc3f18fa7bbcc5f55931a342a0698eb73e7-2000x2000.png?auto=format&w=700 800w,
                    https://cdn.sanity.io/images/13m8empt/production/76b83dc3f18fa7bbcc5f55931a342a0698eb73e7-2000x2000.png?auto=format&w=600 600w,
                    https://cdn.sanity.io/images/13m8empt/production/76b83dc3f18fa7bbcc5f55931a342a0698eb73e7-2000x2000.png?auto=format&w=400 400w,
                    https://cdn.sanity.io/images/13m8empt/production/76b83dc3f18fa7bbcc5f55931a342a0698eb73e7-2000x2000.png?auto=format&w=300 300w"
                  sizes="(min-width: 1000px) 800px, (min-width: 800px) 600px, (min-width: 768px) 500px, calc(100vw - 4rem)"
                  alt="Foreground blocker"
                  class="monitoring-temp-section__image__foreground cover"
                  loading="lazy"
                />

                <picture>
                  <img
                    src="https://cdn.sanity.io/images/13m8empt/production/924e600949b763aea0b1ecb4972d419def0f3e65-2000x2000.svg?auto=format"
                    alt="Point of view illustration of mobile phone with the Varo app open in someones hand."
                    class="monitoring-temp-section__image__foreground "
                    loading="lazy"
                  />
                </picture>

                <picture>
                  <img
                    srcset="
                  https://cdn.sanity.io/images/13m8empt/production/ca6df461c6dac8561de41a4b2ce06a909880105d-2000x2000.png?auto=format&w=1000 1200w,
                  https://cdn.sanity.io/images/13m8empt/production/ca6df461c6dac8561de41a4b2ce06a909880105d-2000x2000.png?auto=format&w=700 800w,
                  https://cdn.sanity.io/images/13m8empt/production/ca6df461c6dac8561de41a4b2ce06a909880105d-2000x2000.png?auto=format&w=600 600w,
                  https://cdn.sanity.io/images/13m8empt/production/ca6df461c6dac8561de41a4b2ce06a909880105d-2000x2000.png?auto=format&w=400 400w,
                  https://cdn.sanity.io/images/13m8empt/production/ca6df461c6dac8561de41a4b2ce06a909880105d-2000x2000.png?auto=format&w=300 200w"
                    sizes="(min-width: 1000px) 50vw - 8rem, (min-width: 768px) 60vw, calc(100vw - 6rem)"
                    src="https://cdn.sanity.io/images/13m8empt/production/ca6df461c6dac8561de41a4b2ce06a909880105d-2000x2000.png?auto=format&w=300"
                    alt="Textured background"
                    aria-hidden="true"
                    role="presentation"
                    class=" monitoring-temp-section__image__background"
                    loading="lazy"
                  />
                </picture>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="monitoring-temp-section__copy">
                <h2 class="headline  monitoring-temp-section__headline">
                  {section.headline}
                </h2>
                <div class="monitoring-temp-section__body">
                  <PortableText value={section.body} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
.monitoring-temp-section__image {
  padding-bottom: 81%;
}

.monitoring-temp-section__image__foreground {
  top: -2px;
}

.monitoring-temp-section .monitoring-temp-section__headline {
  max-width: 25rem;
}

.monitoring-temp-section__image__foreground.cover {
  z-index: 2;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.monitoring-temp-section__image__background {
  max-width: 100%;
}

@media only screen and (min-width: 1000px) {
  .monitoring-temp-section .monitoring-temp-section__image {
    padding-bottom: 110%;
  }
}
@media only screen and (min-width: 1000px) {
  .monitoring-temp-section .monitoring-temp-section__copy {
    max-width: 45ch;
  }
}
</style>
