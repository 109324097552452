<script>
import { page } from '$app/stores';
import { urlFor } from '$lib/sanity';
import { MetaTags } from 'svelte-meta-tags';
import SectionHeroHeader from '$lib/components/sections/sctn-hero-header.svelte';
import SectionVideoEmbed from '$lib/components/sections/sctn-video-embed.svelte';
import SectionWorksForYou from '$lib/components/sections/sctn-works-for-you.svelte';
import SectionUserGroups from '$lib/components/sections/sctn-user-groups.svelte';
import SectionLogs from '$lib/components/sections/sctn-logs.svelte';
import SectionUsingVaro from '$lib/components/sections/sctn-using-varo.svelte';
import SectionPrivateFree from '$lib/components/sections/sctn-private-free.svelte';
import SectionIntegrations from '$lib/components/sections/sctn-integrations-highlight.svelte';
import SectionDownloadCta from '$lib/components/sections/sctn-download-cta.svelte';
import SectionNewsletterSignup from '$lib/components/sections/sctn-newsletter-signup.svelte';

export let data;
</script>

<MetaTags
  title={$page.data.siteHead.seo.title}
  description={$page.data.siteHead.seo.description}
  canonical="https:/www.varo-app.org"
  openGraph={{
    url: 'https://www.url.ie/a',
    title: $page.data.siteHead.seo.title,
    description: $page.data.siteHead.seo.description,
    images: [
      {
        url: urlFor($page.data.siteHead.seo.banner_image.asset).width(1200),
        width: 800,
        height: 600,
        alt: $page.data.siteHead.seo.banner_image.alt_text
          ? $page.data.siteHead.seo.banner_image.alt_text
          : 'Missing Alt Text',
      },
    ],
    site_name: $page.data.siteHead.seo.title,
  }}
  twitter={{
    site: 'www.varo-app.org',
    cardType: 'summary_large_image',
    title: $page.data.siteHead.seo.title,
    description: $page.data.siteHead.seo.description,
    image: urlFor($page.data.siteHead.seo.banner_image.asset).width(1200),
    imageAlt: $page.data.siteHead.seo.banner_image.alt_text
      ? $page.data.siteHead.seo.banner_image.alt_text
      : 'Missing Alt Text',
  }}
/>

{#each data.content.page_layout as section}
  {#if section._type == 'section_hero_header'}
    <SectionHeroHeader {section} />
  {/if}
  {#if section._type == 'section_video_embed'}
    <SectionVideoEmbed {section} />
  {/if}

  {#if section._type == 'section_works_for_you'}
    <SectionWorksForYou {section} />
  {/if}

  {#if section._type == 'section_user_groups'}
    <SectionUserGroups {section} />
  {/if}

  {#if section._type == 'section_logs'}
    <SectionLogs {section} />
  {/if}

  {#if section._type == 'section_using_varo'}
    <SectionUsingVaro {section} />
  {/if}

  {#if section._type == 'section_private_free'}
    <SectionPrivateFree {section} />
  {/if}

  {#if section._type == 'section_integrations_highlight'}
    <SectionIntegrations {section} />
  {/if}

  {#if section._type == 'section_download_cta'}
    <SectionDownloadCta {section} />
  {/if}

  {#if section._type == 'section_newsletter_signup'}
    <SectionNewsletterSignup {section} />
  {/if}
{/each}
