<script>
import { PortableText } from '@portabletext/svelte';
export let section;
</script>

<section class="varo-logs-section">
  <div class="varo-logs-section__inner">
    <div class="container-fluid">
      <div class="row  center-md">
        <div class="col-sm-12">
          <div class="row center-md middle-md  reverse-md">
            <div class="col-sm-12 col-md-6">
              <div class="varo-logs-section__image">
                <img
                  srcset="
               https://cdn.sanity.io/images/13m8empt/production/acc3ac5a2381c9ef88956616cc2c169f99250146-2000x2000.png?auto=format&w=1000 1200w,
               https://cdn.sanity.io/images/13m8empt/production/acc3ac5a2381c9ef88956616cc2c169f99250146-2000x2000.png?auto=format&w=700 800w,
               https://cdn.sanity.io/images/13m8empt/production/acc3ac5a2381c9ef88956616cc2c169f99250146-2000x2000.png?auto=format&w=600 600w,
               https://cdn.sanity.io/images/13m8empt/production/acc3ac5a2381c9ef88956616cc2c169f99250146-2000x2000.png?auto=format&w=400 400w,
               https://cdn.sanity.io/images/13m8empt/production/acc3ac5a2381c9ef88956616cc2c169f99250146-2000x2000.png?auto=format&w=300 200w"
                  sizes="(min-width: 1000px) 50vw - 8rem, (min-width: 768px) 60vw, calc(100vw - 6rem)"
                  src="https://cdn.sanity.io/images/13m8empt/production/acc3ac5a2381c9ef88956616cc2c169f99250146-2000x2000.png?auto=format&w=300"
                  alt="Covering"
                  class="cover"
                  loading="lazy"
                  role="presentation"
                />
                <img
                  src="https://cdn.sanity.io/images/13m8empt/production/4f54614e4e11c43b766798e99183fa43a845db25-2000x2000.svg"
                  alt="Illustration of a phone with the Varo app open showing the different temperatures of a cold chain fridge on a month to month basis. It is also plugged into a Micro USB and "
                  class="phone-image"
                />
                  <img
                    srcset="
                  https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format&w=1000 1200w,
                  https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format&w=700 800w,
                  https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format&w=600 600w,
                  https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format&w=400 400w,
                  https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format&w=300 200w"
                    sizes="(min-width: 1000px) 50vw - 8rem, (min-width: 768px) 60vw, calc(100vw - 6rem)"
                    src="https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format&w=300"
                    alt="Textured background"
                    aria-hidden="true"
                    role="presentation"
                    class="varo-logs-section__image__texture"
                    loading="lazy"
                  />
              </div>
            </div>
            <div class="col-sm-12 col-md-6 ">
              <div class="varo-logs-section__copy">
                <h2 class="headline  varo-logs-section__headline">
                  {section.headline}
                </h2>
                <div class="varo-logs-section__body">
                  <PortableText value={section.body} />

                  {#if section.link}
                    {#if section.link.linkType == 'external'}
                      <a
                        href={section.link.href}
                        title="Download Varo on the Google Play store"
                        target="_blank"
                        rel="external"
                        class="varo-logs-section__body__button"
                      >
                        <svg>
                          <title>Get it on Google Play</title>
                          <use xlink:href="#google-play-button" />
                        </svg>
                      </a>
                    {/if}

                    {#if section.link.linkType == 'internal'}
                      <a
                        href="/{section.link.internalLink.handle.current}"
                        rel="internal"
                        title="Download Varo on the Google Play store"
                        class="varo-logs-section__body__button"
                      >
                        <svg>
                          <title>Get it on Google Play</title>
                          <use xlink:href="#google-play-button" />
                        </svg>
                      </a>
                    {/if}
                  {/if}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
.cover {
  position: absolute;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  bottom: 0;
}

@media only screen and (min-width: 1000px) {
  .varo-logs-section .varo-logs-section__copy {
    max-width: 27rem;
  }
}

@media only screen and (min-width: 1000px) {
  .varo-logs-section .varo-logs-section__body {
    font-size: 1.15rem;
  }
}

.varo-logs-section__image {
  z-index: -1;
}
</style>
