<script>
import { PortableText } from '@portabletext/svelte';
export let section;
</script>

<section class="secure-section">
  <div class="secure-section__inner">
    <div class="container-fluid">
      <div class="row  center-md">
        <div class="col-sm-12 col-lg-11">
          <div class="row  center-md middle-md reverse-md">
            <div class="col-sm-12 col-md-6">
              <div class="secure-section__image">
                <img
                  src="https://cdn.sanity.io/images/13m8empt/production/57cf3e933e72a8dbd9e38a94d20cca52ecb51cc0-1066x873.svg"
                  alt="Illustration of envelope with a Varo stamp on it."
                  class="  secure-section__image__foreground"
                  loading="lazy"
                  data-ui-component="secure-varo-envelope"
                />
                <img
                  srcset="
                 https://cdn.sanity.io/images/13m8empt/production/f4661c53c5fbf547e62d3e0529323abf2fe7454f-2000x2000.png?auto=format&w=1000 9000w,
                 https://cdn.sanity.io/images/13m8empt/production/f4661c53c5fbf547e62d3e0529323abf2fe7454f-2000x2000.png?auto=format&w=700 800w,
                 https://cdn.sanity.io/images/13m8empt/production/f4661c53c5fbf547e62d3e0529323abf2fe7454f-2000x2000.png?auto=format&w=600 600w,
                 https://cdn.sanity.io/images/13m8empt/production/f4661c53c5fbf547e62d3e0529323abf2fe7454f-2000x2000.png?auto=format&w=400 400w,
                 https://cdn.sanity.io/images/13m8empt/production/f4661c53c5fbf547e62d3e0529323abf2fe7454f-2000x2000.png?auto=format&w=300 300w"
                  sizes="(min-width: 1000px) 800px, (min-width: 768px) 50vw - 4rem, calc(100vw - 6rem)"
                  src="https://cdn.sanity.io/images/13m8empt/production/f4661c53c5fbf547e62d3e0529323abf2fe7454f-2000x2000.png?auto=format&w=300w"
                  alt="Textured background"
                  class="  secure-section__image__background"
                  aria-hidden="true"
                  loading="lazy"
                  role="presentation"
                />
              </div>
            </div>
            <div class="col-sm-12  col-md-6  ">
              <div class="secure-section__copy">
                <span class="headline-accent">Private & Secure</span>
                <h1 class="headline  secure-section__headline">
                  {section.headline}
                </h1>
                <div class="secure-section__body">
                  <PortableText value={section.body} />
                </div>

                {#if section.cta_link}
                  {#if section.cta_link.linkType == 'external'}
                    <a
                      href={section.cta_link.href}
                      title=" {section.cta_link_text}"
                      target="_blank"
                      rel="external"
                      class="button button--secondary  button--medium  secure-section__button"
                    >
                      {section.cta_link_text}
                    </a>
                  {/if}

                  {#if section.cta_link.linkType == 'internal'}
                    <a
                      href="/{section.cta_link.internalLink.handle.current}"
                      rel="internal"
                      title=" {section.cta_link_text}"
                      class="button button--secondary  button--medium  secure-section__button"
                    >
                      {section.cta_link_text}
                    </a>
                  {/if}
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
.secure-section .secure-section__image .secure-section__image__foreground {
  max-width: 100%;
}

@media screen and (min-width: 1000px) {
  .secure-section .secure-section__image .secure-section__image__foreground {
    width: 55%;
    top: 2rem;
  }
}

.secure-section__image {
  overflow: visible !important;
}

.s-SK2r9Rzm3AV-.s-SK2r9Rzm3AV- {
  overflow: visible;
}

.secure-section .secure-section__image .secure-section__image__background {
  right: auto;
  left: -4rem;
  bottom: 0;
  top: auto;
  z-index: -1;
  -o-object-fit: contain;
  object-fit: contain;
  width: 110%;
  max-width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .secure-section .secure-section__image .secure-section__image__background {
    left: 0;
    width: 110%;
    top: 0rem;
    max-width: 110%;
    height: 110%;
  }
}
</style>
