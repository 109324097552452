<script>
import { PortableText } from '@portabletext/svelte';
import { onMount } from 'svelte';
import { urlFor } from '$lib/sanity';
export let section;

onMount(() => {});
</script>

<section class="user-groups-section">
  <div class="container-fluid">
    <div class="user-groups-section__header">
      <div class="row">
        <div class="col-sm-12">
          <h2 class="headline  user-groups-section__header__headline">
            {section.headline}
          </h2>
        </div>
      </div>
    </div>
    <div class="user-groups-section__groups">
      <div class="row  stretch-md">
        {#each section.list as userGroup, index}
          <div class="col-sm-12 col-md-4">
            <div
              class="user-groups-section__group  user-groups-section__group--healthcare"
            >
              <div class="user-groups-section__group__image">
                <picture>
                  <img
                    src={urlFor(userGroup.image.asset)}
                    alt="Healthcare staff attending to patients in a clinic"
                    loading="lazy"
                  />
                </picture>
                {#if index % 2 == 0}
                  <img
                    src="https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format"
                    alt="Healthcare staff attending to patients in a clinic"
                    loading="lazy"
                    class="user-groups-section__group__image-bg  hide-for-medium-up"
                  />
                {:else}
                  <img
                    src="https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format"
                    alt="Healthcare staff attending to patients in a clinic"
                    loading="lazy"
                    class="user-groups-section__group__image-bg  hide-for-medium-up"
                  />
                {/if}
              </div>
              <div class="user-groups-section__group__info">
                <h3 class="headline  user-groups-section__group__headline">
                  {userGroup.headline}
                </h3>
                <div class="user-groups-section__group__body">
                  <PortableText value={userGroup.body} />
                </div>
              </div>
            </div>
          </div>
        {/each}
        <picture>
          <source
            srcset="https://cdn.sanity.io/images/13m8empt/production/53b6d4f2a8faa729c2d837c1c96c44e2a28fbc75-3840x1280.png?w=1000&auto=format"
            media="(min-width: 768px)"
          />
          <img
            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.pikpng.com%2Fpngl%2Fm%2F97-971526_empty-pixel-heart-pink-8-bit-heart-clipart.png&f=1&nofb=1&ipt=e42f3f716b577fb21417a7f24e867193db2b0bd0d20aede6b64913fb28c14970&ipo=images"
            alt=""
            loading="lazy"
            aria-hidden="true"
            role="presentation"
            class="user-groups-section__bg"
          />
        </picture>

        <picture>
          <source
            srcset="https://cdn.sanity.io/images/13m8empt/production/f9303b9fe343a190e2e1954b25a1b63ed9d2542e-3840x1280.png?w=1000&auto=format"
            media="(min-width: 768px)"
          />
          <img
            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.pikpng.com%2Fpngl%2Fm%2F97-971526_empty-pixel-heart-pink-8-bit-heart-clipart.png&f=1&nofb=1&ipt=e42f3f716b577fb21417a7f24e867193db2b0bd0d20aede6b64913fb28c14970&ipo=images"
            alt=""
            loading="lazy"
            aria-hidden="true"
            role="presentation"
            class="user-groups-section__bg"
          />
        </picture>
      </div>
    </div>
  </div>
</section>

<style>
/* @media screen and (min-width: 1000px) {
  .user-groups-section {
    background-image: url(https://cdn.sanity.io/images/13m8empt/production/489a5c371098d3938787bcccd47d60df75ef356c-3840x1280.png);
    background-position: 50% 30%;
    background-size: 80%;
  }
} */

.user-groups-section__group__image-bg {
  z-index: -1;
}
.user-groups-section
  .user-groups-section__group
  .user-groups-section__group__image {
  margin-bottom: 1rem;
  padding-bottom: 63%;
  height: auto;
}

.user-groups-section
  .user-groups-section__group
  .user-groups-section__group__image
  img {
  object-fit: contain;
}

@media screen and (min-width: 768px) {
  .user-groups-section
    .user-groups-section__group
    .user-groups-section__group__image {
    padding-bottom: 70%;
  }
}

@media screen and (min-width: 768px) {
  .user-groups-section
    .user-groups-section__group
    .user-groups-section__group__image {
    padding-bottom: 81%;
  }
}

@media screen and (min-width: 1000px) {
  .user-groups-section
    .user-groups-section__group
    .user-groups-section__group__image
    img {
    width: 100%;
    margin-bottom: 0;
    object-fit: contain;
  }
}

@media screen and (min-width: 768px) {
  .user-groups-section__header {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 1000px) {
  .user-groups-section__header {
    margin-bottom: 4rem;
  }
}

.user-groups-section__bg {
  display: none;
}

@media screen and (min-width: 768px) {
  .user-groups-section__bg {
    position: absolute;
    z-index: -1;
    top: 5rem;
    width: 110vw;
    margin: auto;
    left: -8vw;
    right: 0;
    display: block;
    max-width: 102rem;
    min-width: 55rem;
  }
}

@media screen and (min-width: 1400px) {
  .user-groups-section__bg {
    top: 12rem;
  }
}

@media screen and (min-width: 1400px) {
  .user-groups-section__bg {
    width: 82rem;
    left: 0;
    right: 0;
    margin: auto;
    top: 7rem;
  }
}
</style>
