<script>
import { PortableText } from '@portabletext/svelte';
export let section;
</script>

<section class="home-video-section">
  <div class="container-fluid">
    <div class="row  center-md">
      <div class="col-sm-12 col-md-10 col-lg-12">
        <div class="home-video-section__inner">
          <div class="home-video-section__video responsive-video">
            {@html section.iframe}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
