<script>
import { PortableText } from '@portabletext/svelte';
export let section;
</script>

<section class="integrations-section">
  <div class="integrations-section__inner">
    <div class="container-fluid">
      <div class="row  center-md">
        <div class="col-sm-12  col-lg-11">
          <div class="row  center-md middle-md">
            <div class="col-sm-12 col-md-6">
              <div class="integrations-section__image">
                <picture>
                  <img
                    src="https://cdn.sanity.io/images/13m8empt/production/1f635712696a932dde4394b8f1d83edf0505229a-2000x2000.svg"
                    alt="Textured background"
                    aria-hidden="true"
                    role="presentation"
                    class="integrations-secton__image__foreground"
                    loading="lazy"
                  />
                </picture>

                <img
                  srcset="
                  https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format&w=1000 1200w,
                  https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format&w=700 800w,
                  https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format&w=600 600w,
                  https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format&w=400 400w,
                  https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png?auto=format&w=300 200w"
                  sizes="(min-width: 1000px) 50vw - 8rem, (min-width: 768px) 60vw, calc(100vw - 6rem)"
                  src="https://cdn.sanity.io/images/13m8empt/production/19189ce142671cb34a8f35f37361f7bb12649d90-2000x2000.png&w=300"
                  alt="Textured background"
                  aria-hidden="true"
                  role="presentation"
                  class="integrations-secton__image__background "
                  loading="lazy"
                />
              </div>
            </div>
            <div class="col-sm-12 col-md-6 ">
              <div class="integrations-section__copy">
                <span class="headline-accent"> {section.headline} </span>
                <h1 class="headline  integrations-section__headline">
                  {section.subline}
                </h1>
                <div class="integrations-section__body">
                  <PortableText value={section.body} />
                </div>

                {#if section.cta_link}
                  {#if section.cta_link.linkType == 'external'}
                    <a
                      href={section.cta_link.href}
                      title="Read about Varo integrations"
                      target="_blank"
                      rel="external"
                      class="button button--secondary  button--medium  integrations-section__button"
                    >
                      {section.cta_link_text}
                    </a>
                  {/if}

                  {#if section.cta_link.linkType == 'internal'}
                    <a
                      href="/{section.cta_link.internalLink.handle.current}"
                      rel="internal"
                      title="Read about Varo integrations"
                      class="button button--secondary  button--medium  integrations-section__button"
                    >
                      {section.cta_link_text}
                    </a>
                  {/if}
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
.integrations-section__image {
  transform: scaleY(1);
  padding-bottom: 71%;
}
@media screen and (min-width: 768px) {
  .integrations-secton__image__foreground {
    width: 120%;
    height: 110%;
    left: -3rem;
    transform: scaleY(1);
  }
}

.integrations-section .integrations-section__image {
  margin-bottom: 1.25rem;
}

@media only screen and (min-width: 768px) {
  .integrations-section .integrations-section__image {
    padding-bottom: 100%;
    margin-bottom: 0;
  }
}

.integrations-section
  .integrations-section__image
  .integrations-secton__image__background {
  right: auto;
  bottom: 0;
  left: -2rem;
  top: auto;
  z-index: -1;
  -o-object-fit: contain;
  object-fit: contain;
  width: 110%;
  max-width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .integrations-section
    .integrations-section__image
    .integrations-section__image__background {
    width: 110%;
    top: 0rem;
    max-width: 110%;
    height: 110%;
    left: -2rem;
  }
}

@media only screen and (min-width: 768px) {
  .integrations-section
    .integrations-section__image
    .integrations-secton__image__background {
    right: 0;
    left: -4rem;
  }
}
</style>
