<script>
import { PortableText } from "@portabletext/svelte";
import { urlFor } from "$lib/sanity";
import { onMount, onDestroy } from "svelte";
import Swiper, { Controller, Navigation, Pagination } from "swiper";
export let section;
export let container;

Swiper.use([Navigation, Pagination, Controller]);
let ScrollTrigger;
let gsap;
let tl;
let usingVaroAnims;

onMount(async () => {
  console.log("MOUNTING SCTN USING VARO");
  const loadModules = async () => {
    const module = await import("gsap");
    gsap = module.default;

    const scrollTriggerModule = await import("gsap/ScrollTrigger");
    ScrollTrigger = scrollTriggerModule.ScrollTrigger;
    const ScrollToPlugin = await import("gsap/ScrollToPlugin");
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  };

  await loadModules();

  usingVaroAnims = {
    selectors: {
      scrollMenu: '[data-ui-component="using-varo-scroll-menu"]',
      stepsWrapper: '[data-ui-component="using-varo-steps-wrapper"]',
      steps: "[data-ui-component='using-varo-steps']",
      step: "[data-ui-component='using-varo-step']",
      usingVaroContainer: "[data-ui-component='using-varo']",
      varoScreensWrapper: "[data-ui-component='using-varo-screens-wrapper']",
      varoScreens: "[data-ui-component='using-varo-screens']",
      varoScreen: "[data-ui-component='using-varo-screen']",
      varoStepBullet: "[data-ui-component='using-varo-step-bullet']",
      varoPhone: "[data-ui-component='using-varo-phone']",
    },
    copySection: {
      stepsHeadline: null,
    },
    animationDuration: "600%",
    init: function () {
      var currentBreakpoint = window
        .getComputedStyle(document.querySelector("body"), ":before")
        .getPropertyValue("content")
        .replace(/"/g, "");

      if (currentBreakpoint == "medium-up" || currentBreakpoint == "large-up") {
        this.initDesktop();
      } else {
        this.initMobile();
      }

      var timerId = null;

      var throttle = function (func, delay) {
        // If setTimeout is already scheduled, no need to do anything
        if (timerId) {
          return;
        }

        // Schedule a setTimeout after delay seconds
        timerId = setTimeout(function () {
          func();

          // Once setTimeout function execution is finished, timerId = undefined so that in <br>
          // the next scroll event function execution can be scheduled by the setTimeout
          timerId = undefined;
        }, delay);
      };

      window.addEventListener("resize", () => {
        throttle(function () {
          usingVaroAnims.init();
        }, 300);
      });
    },
    initDesktop: function () {
      let that = this;

      if (tl) {
        tl.kill();
      }

      if (this.usingVaroScreensSlideshow) {
        this.usingVaroScreensSlideshow.destroy(true, true);
        //this.cleanupSlideshow();
      }

      if (ScrollTrigger) {
        ScrollTrigger.refresh();
        ScrollTrigger.getAll().forEach((trigger) => {
          if (trigger.trigger !== this.selectors.usingPogo) {
            trigger.kill();
            // Clean up any inline styles generated by GSAP ScrollTrigger natively
            gsap.set(trigger.trigger, { clearProps: "all" });
          }
        });
      }

      // if (this.usingVaroScreensSlideshow) {
      //   container
      //     .querySelector(selectors.steps)
      //     .querySelector(selectors.stepsWrapper)
      //     .classList.remove(".swiper-wrapper");

      //   container.querySelectorAll(selectors.step).forEach((elem) => {
      //     elem.classList.remove("swiper-slide");
      //   });

      //   container
      //     .querySelector(selectors.steps)
      //     .classList.remove("swiper-container");

      //   container
      //     .querySelector(selectors.stepsWrapper)
      //     .classList.remove("swiper-wrapper");

      //   container.querySelectorAll(selectors.varoScreen).forEach((elem) => {
      //     elem.classList.remove("swiper-slide");
      //   });

      //   if (container.querySelectorAll(".swiper-pagination").length > 0) {
      //     container.querySelector(".swiper-pagination").remove();
      //   }

      //   this.usingVaroScreensSlideshow.destroy(true, true);
      //   this.usingVaroScreensSlideshow = null;

      //   if (this.usingVaroStepsSlideshow) {
      //     this.usingVaroStepsSlideshow.destroy(true, true);
      //     this.usingVaroStepsSlideshow = null;
      //   }

      //   if (this.usingVaroSwipeSlideshow) {
      //     this.usingVaroSwipeSlideshow.destroy(true, true);
      //     this.usingVaroSwipeSlideshow = null;
      //     document
      //       .querySelector('[data-ui-component="using-varo-swipe-slideshow"]')
      //       .querySelector(".swiper-button-prev")
      //       .remove();
      //     document
      //       .querySelector('[data-ui-component="using-varo-swipe-slideshow"]')
      //       .querySelector(".swiper-button-next")
      //       .remove();
      //     document
      //       .querySelector(selectors.varoScreensWrapper)
      //       .classList.remove("swiper-wrapper");
      //   }
      // }

      // if (this.stepsHeadline) {
      //   $slides.append(this.stepsHeadline);
      // }

      let animationDuration = this.animationDuration;

      if (!container) {
        return;
      }

      this.slideshow = container.querySelector(this.selectors.varoScreens);
      this.slides = container.querySelectorAll(this.selectors.varoScreen);

      this.slideshow.classList.add("swiper-container");

      this.slideshow
        .querySelector(this.selectors.varoScreensWrapper)
        .classList.add("swiper-wrapper");

      this.slides.forEach((elem) => {
        elem.classList.add("swiper-slide");
      });

      this.usingVaroScreensSlideshow = new Swiper(this.selectors.varoScreens, {
        direction: "horizontal",
        slidesPerView: 1,
        autoHeight: false,
        touchReleaseOnEdges: false,
      });

      const usingVaroContainer = container;

      let currentSlide = 0;

      // if (window.innerWidth > 1000) {
      //   this.progressTween.to(
      //     '[data-step-id="00"]',
      //     { y: -40, opacity: 0, ease: "power2.inOut", duration: 1.75 },
      //     "-=3"
      //   );
      // }
      // this.scrollTrigger = ScrollTrigger.create({
      //   trigger: this.selectors.usingVaroContainer,
      //   start: "top",
      //   end: `+=${animationDuration}`,
      //   pin: true,
      //   scrub: 1,
      //   anticipatePin: 1,
      //   onUpdate: (self) => {
      //     if (self.progress === 0) {
      //       document
      //         .querySelectorAll(this.selectors.varoStepBullet)[0]
      //         .classList.remove("is-checked");
      //     }
      //   },
      // });

      this.tl = gsap.timeline();

      ScrollTrigger.create({
        animation: this.tl,
        trigger: this.selectors.usingVaroContainer,
        start: "top 50%",
        end: `+=${animationDuration}`,
        scrub: true,
      });
      this.tl
        .fromTo(
          '[data-step-id="00"]',
          { y: 60, opacity: 0, ease: "power2.inOut" },
          { y: 0, opacity: 1, ease: "power2.inOut", duration: 3 }
        )
        .fromTo(
          this.selectors.varoPhone,
          { y: 20, opacity: 0, ease: "power2.inOut" },
          { y: 0, opacity: 1, ease: "power2.inOut", duration: 3 },
          "-=2"
        )
        .fromTo(
          this.selectors.scrollMenu,
          { y: 20, opacity: 0, ease: "power2.inOut" },
          { y: 0, opacity: 1, ease: "power2.inOut", duration: 3 },
          "-=2"
        )
        .to(
          '[data-ui-component="home-screen"]',
          { scale: 1.3, opacity: 0, ease: "power2.inOut", duration: 4 },
          "+=2"
        )
        .add(() => {
          container
            .querySelectorAll(this.selectors.varoStepBullet)[0]
            .classList.remove("is-active");
        })
        .fromTo(
          '[data-step-id="00"]',
          { y: 0, opacity: 1, ease: "power2.inOut" },
          { y: -60, opacity: 0, ease: "power2.inOut", duration: 3 }
        )
        .fromTo(
          '[data-step-id="0"]',
          { y: 20, opacity: 0, ease: "power2.inOut" },
          {
            y: 0,
            opacity: 1,
            ease: "power2.inOut",
            duration: 3,
            onUpdate: () => {
              container
                .querySelectorAll(this.selectors.varoStepBullet)[0]
                .classList.remove("is-active");
            },
            onComplete: () => {
              container
                .querySelectorAll(this.selectors.varoStepBullet)[0]
                .classList.add("is-active");
            },
          }
        )
        .fromTo(
          ".using-varo-section__scroll-menu__scroll-line__progress",
          { height: 0 },
          { height: "25%", duration: 10 }
        )
        .add(() => {
          that.usingVaroScreensSlideshow.slideTo(0);
        })
        .to('[data-step-id="0"]', {
          y: -40,
          opacity: 0,
          ease: "power2.inOut",
          duration: 3,
        })
        .fromTo(
          '[data-step-id="1"]',
          { y: 20, opacity: 0, ease: "power2.inOut" },
          {
            y: 0,
            opacity: 1,
            ease: "power2.inOut",
            duration: 3,
            onUpdate: () => {
              container
                .querySelectorAll(this.selectors.varoStepBullet)[1]
                .classList.remove("is-active");
            },
            onComplete: () => {
              container
                .querySelectorAll(this.selectors.varoStepBullet)[0]
                .classList.remove("is-active");

              container
                .querySelectorAll(this.selectors.varoStepBullet)[1]
                .classList.add("is-active");
              that.usingVaroScreensSlideshow.slideTo(1);
            },
          }
        )

        .to(".using-varo-section__scroll-menu__scroll-line__progress", {
          height: "51%",
          duration: 10,
        })
        .add(() => {
          that.usingVaroScreensSlideshow.slideTo(1);
        })
        .to('[data-step-id="1"]', {
          y: -40,
          opacity: 0,
          ease: "power2.inOut",
          duration: 2,
        })
        .add(() => {
          that.usingVaroScreensSlideshow.slideTo(2);
        })
        .fromTo(
          '[data-step-id="2"]',
          { y: 20, opacity: 0, ease: "power2.inOut" },
          {
            y: 0,
            opacity: 1,
            ease: "power2.inOut",
            duration: 3,
            onUpdate: () => {
              container
                .querySelectorAll(this.selectors.varoStepBullet)[2]
                .classList.remove("is-active");
            },
            onComplete: () => {
              container
                .querySelectorAll(this.selectors.varoStepBullet)[1]
                .classList.remove("is-active");

              container
                .querySelectorAll(this.selectors.varoStepBullet)[2]
                .classList.add("is-active");
              that.usingVaroScreensSlideshow.slideTo(2);
            },
          }
        )

        .to(".using-varo-section__scroll-menu__scroll-line__progress", {
          height: "74%",
          duration: 10,
        })
        .add(() => {
          that.usingVaroScreensSlideshow.slideTo(2);
        })
        .to('[data-step-id="2"]', {
          y: -40,
          opacity: 0,
          ease: "power2.inOut",
          duration: 2,
        })
        .add(() => {
          that.usingVaroScreensSlideshow.slideTo(3);
        })
        .fromTo(
          '[data-step-id="3"]',
          { y: 20, opacity: 0, ease: "power2.inOut" },
          {
            y: 0,
            opacity: 1,
            ease: "power2.inOut",
            duration: 3,
            onUpdate: () => {
              container
                .querySelectorAll(this.selectors.varoStepBullet)[3]
                .classList.remove("is-active");
            },
            onComplete: () => {
              container
                .querySelectorAll(this.selectors.varoStepBullet)[2]
                .classList.remove("is-active");

              container
                .querySelectorAll(this.selectors.varoStepBullet)[3]
                .classList.add("is-active");
              that.usingVaroScreensSlideshow.slideTo(3);
            },
          },
          "-=1.75"
        )
        .add(() => {
          that.usingVaroScreensSlideshow.slideTo(3);
        })
        .to(".using-varo-section__scroll-menu__scroll-line__progress", {
          height: "100%",
          duration: 10,
        })

        .add(() => {
          that.usingVaroScreensSlideshow.slideTo(4);
        }, "-=1")
        .to(
          '[data-step-id="3"]',
          { y: -40, opacity: 0, ease: "power2.inOut", duration: 2 },
          "-=2"
        )
        .fromTo(
          '[data-step-id="4"]',
          { y: 20, opacity: 0, ease: "power2.inOut" },
          {
            y: 0,
            opacity: 1,
            ease: "power2.inOut",
            duration: 3,
            onUpdate: () => {
              container
                .querySelectorAll(this.selectors.varoStepBullet)[4]
                .classList.remove("is-active");
            },
            onComplete: () => {
              container
                .querySelectorAll(this.selectors.varoStepBullet)[3]
                .classList.remove("is-active");

              container
                .querySelectorAll(this.selectors.varoStepBullet)[4]
                .classList.add("is-active");
              that.usingVaroScreensSlideshow.slideTo(4);
            },
          },
          "-=1.75"
        );
    },
    initMobile: function () {
      let that = this;

      if (this.controller) {
        this.controller.destroy(true);
        this.progressBarAnim.destroy(true);

        const keys = Object.values(this.selectors);

        this.progressTween.clear();

        for (var i = 0; i < keys.length; i++) {
          if (container.querySelector(keys[i]) !== null) {
            that.progressTween.set(keys[i], {
              clearProps: "transform, opacity",
            });
            container.querySelector(keys[i]).removeAttribute("style");
          }
          container
            .querySelectorAll('[data-ui-component="using-varo-step"]')
            .forEach((elem) => {
              elem.removeAttribute("style");
            });
        }
      }

      if (this.usingVaroScreensSlideshow) {
        this.usingVaroScreensSlideshow.destroy(false, true);
        this.usingVaroScreensSlideshow = null;
      }

      let slideshowText = container.querySelector(this.selectors.steps);
      let slidesText = container.querySelectorAll(this.selectors.step);

      slideshowText
        .querySelector(this.selectors.stepsWrapper)
        .classList.add("swiper-wrapper");

      slidesText.forEach((elem) => {
        elem.classList.add("swiper-slide");
      });

      var swiperComponents = '<div class="swiper-pagination"></div>';
      slideshowText.insertAdjacentHTML("beforeend", swiperComponents);

      that.usingVaroStepsSlideshow = new Swiper(this.selectors.steps, {
        direction: "horizontal",
        slidesPerView: 1,
        autoHeight: false,
        touchReleaseOnEdges: false,
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });

      that.stepHeadline = document
        .querySelector('[data-step-id="00"]')
        .cloneNode(true);
      container.querySelector('[data-step-id="00"]').style.display = "none";

      that.usingVaroStepsSlideshow.update();

      let slideshow = container.querySelector(this.selectors.varoScreens);
      let slides = container.querySelectorAll(this.selectors.varoScreen);

      slideshow.classList.add("swiper-container");

      slideshow
        .querySelector(this.selectors.varoScreensWrapper)
        .classList.add("swiper-wrapper");

      slides.forEach((elem) => {
        elem.classList.add("swiper-slide");
      });

      that.usingVaroScreensSlideshow = new Swiper(this.selectors.varoScreens, {
        direction: "horizontal",
        slidesPerView: 1,
        autoHeight: true,
        touchReleaseOnEdges: false,
        fadeEffect: {
          crossFade: true,
        },
      });

      var swipeSlideshow = container.querySelector(
        '[data-ui-component="using-varo-swipe-slideshow"]'
      );
      var swipeSlideshowSlides = container.querySelectorAll(
        '[data-ui-component="using-varo-swipe-slideshow-slide"]'
      );
      swipeSlideshow
        .querySelector(
          '[data-ui-component="using-varo-swipe-slideshow-wrapper"]'
        )
        .classList.add("swiper-wrapper");

      swipeSlideshowSlides.forEach((elem) => {
        elem.classList.add("swiper-slide");
      });

      swipeSlideshow.insertAdjacentHTML(
        "beforeend",
        '<div class="swiper-button-prev"></div> <div class="swiper-button-next"></div>'
      );

      that.usingVaroSwipeSlideshow = new Swiper(
        '[data-ui-component="using-varo-swipe-slideshow"]',
        {
          direction: "horizontal",
          slidesPerView: 1,
          autoHeight: true,
          touchReleaseOnEdges: false,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          fadeEffect: {
            crossFade: true,
          },
        }
      );

      that.usingVaroStepsSlideshow.controller.control = [
        that.usingVaroScreensSlideshow,
      ];

      this.usingVaroScreensSlideshow.controller.control = [
        this.usingVaroStepsSlideshow,
      ];

      this.usingVaroSwipeSlideshow.controller.control = [
        this.usingVaroStepsSlideshow,
      ];
    },
  };

  usingVaroAnims.init();
});

onDestroy(() => {
  if (!usingVaroAnims || !usingVaroAnims.usingVaroScreensSlideshow) {
    return;
  }

  if (tl) {
    tl.kill();
  }

  if (ScrollTrigger) {
    ScrollTrigger.getAll().forEach((trigger) => {
      trigger.kill();
      gsap.set(trigger.trigger, { clearProps: "all" });
    });
  }

  if (usingVaroAnims.usingVaroScreensSlideshow) {
    usingVaroAnims.usingVaroScreensSlideshow.destroy(true, true);
  }

  if (usingVaroAnims.usingVaroStepsSlideshow) {
    usingVaroAnims.usingVaroStepsSlideshow.destroy(true, true);
  }

  if (usingVaroAnims.usingVaroSwipeSlideshow) {
    usingVaroAnims.usingVaroSwipeSlideshow.destroy(true, true);
  }
});
</script>

<section
  class="using-varo-section"
  data-ui-component="using-varo"
  bind:this={container}
>
  <div class="using-varo-section__inner">
    <div class="container-fluid">
      <div class="row center-md">
        <div class="col-sm-12">
          <div class="using-varo-section__header">
            <h2
              class="headline using-varo-section__header__headline hide-for-large-up"
              data-ui-component="using-varo-headline"
            >
              {section.headline}
            </h2>
          </div>
        </div>
      </div>

      <div class="row top-md">
        <div class="col-sm-12 col-lg-6">
          <div class="using-varo-section__phone-container">
            <div
              class="using-varo-section__scroll-menu"
              data-ui-component="using-varo-scroll-menu"
            >
              <div class="using-varo-section__scroll-menu-wrapper">
                {#each section.list as bullet, index}
                  <div
                    class="using-varo-section__scroll-menu__point"
                    data-ui-component="using-varo-step-bullet"
                    data-id={index}
                  >
                    <div class="using-varo-section__scroll-menu__point__text">
                      {bullet.headline}
                    </div>

                    <div
                      class="using-varo-section__scroll-menu__point__bullet"
                    />
                  </div>
                {/each}

                <div class="using-varo-section__scroll-menu__scroll-line">
                  <div
                    class="using-varo-section__scroll-menu__scroll-line__progress"
                    style="will-change: width, height; height: 47.0935%;"
                  />
                </div>
              </div>
            </div>

            <div class="using-varo-section__phone">
              <div data-ui-component="using-varo-phone">
                <div
                  class="using-varo-swipe-slideshow"
                  data-ui-component="using-varo-swipe-slideshow"
                >
                  <div data-ui-component="using-varo-swipe-slideshow-wrapper">
                    {#each section.list as step}
                      <div
                        data-ui-component="using-varo-swipe-slideshow-slide"
                        class="using-varo-swipe-slideshow__slide"
                      />
                    {/each}
                  </div>
                </div>

                <div class="using-varo-section__phone__frame">
                  <svg>
                    <title>Phone Frame</title>
                    <use xlink:href="#phone-frame" />
                  </svg>
                </div>

                <div
                  class="using-varo-section__phone__screens"
                  data-ui-component="using-varo-screens"
                >
                  <div
                    class="using-varo-section__phone__screens__home-screen"
                    data-ui-component="home-screen"
                  >
                    <div class="screen-container">
                      <div class="varo-logo">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          x="0"
                          y="0"
                          version="1.1"
                          viewBox="0 0 233 232.3"
                          xml:space="preserve"
                        >
                          <title> Varo logo icon </title>
                          <path
                            fill="#00abe8"
                            d="M91.4 0C173 1.6 231.4 60.1 233 142c0 1.9-1.5 3.5-3.5 3.5H202c-1.9 0-3.4-1.5-3.4-3.4-1.8-58.6-48.8-105.8-107.3-107.6-1.9-.1-3.4-1.6-3.4-3.4V3.5c0-2 1.6-3.5 3.5-3.5z"
                          />
                          <path
                            fill="#28aae1"
                            d="M90.3 105.5c-1.3-.1-2.3 1-2.3 2.3v35.1c0 1.5 1.2 2.6 2.6 2.6h35.1c1.3 0 2.3-1.1 2.2-2.3-1.2-20.3-17.4-36.6-37.6-37.7z"
                          />
                          <path
                            fill="#09638a"
                            d="M93.1 48.9c51.3 0 93.1 41.9 93.1 91.3 0 50.2-41.8 92.2-93.1 92.2S0 190.4 0 140.2c0-49.4 41.8-91.3 93.1-91.3zm0 136.3c24.1 0 43.6-20.1 43.6-45 0-24.5-19.5-44.1-43.6-44.1-24.5 0-43.6 19.7-43.6 44.1 0 24.9 19.1 45 43.6 45z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div data-ui-component="using-varo-screens-wrapper">
                    {#each section.list as step, index}
                      <div
                        class="using-varo-section__phone__screens__screen"
                        data-ui-component="using-varo-screen"
                        data-step={index}
                      >
                        <div class="screen-container">
                          <img
                            src=""
                            srcset="{urlFor(step.screen_image.asset)
                              .width(400)
                              .auto('format')} 400w,
							 		 {urlFor(step.screen_image.asset).width(600).auto('format')} 600w,
							 		 {urlFor(step.screen_image.asset).width(800).auto('format')} 800w"
                            sizes="50vw, calc(100vw - 4rem)"
                            loading="lazy"
                            decoding="async"
                            alt={step.screen_image.alt_text}
                          />
                        </div>
                      </div>
                    {/each}
                  </div>
                </div>
              </div>

              <div class="using-varo-section__phone-bg">
                <img
                  srcset="
                   https://cdn.sanity.io/images/13m8empt/production/4a5cd7c67c22dc3d764874582ad41b89f61fcf94-2000x2000.png?auto=format&w=1000 1200w,
                   https://cdn.sanity.io/images/13m8empt/production/4a5cd7c67c22dc3d764874582ad41b89f61fcf94-2000x2000.png?auto=format&w=700 800w,
                   https://cdn.sanity.io/images/13m8empt/production/4a5cd7c67c22dc3d764874582ad41b89f61fcf94-2000x2000.png?auto=format&w=600 600w,
                   https://cdn.sanity.io/images/13m8empt/production/4a5cd7c67c22dc3d764874582ad41b89f61fcf94-2000x2000.png?auto=format&w=400 400w,
                   https://cdn.sanity.io/images/13m8empt/production/4a5cd7c67c22dc3d764874582ad41b89f61fcf94-2000x2000.png?auto=format&w=300 200w"
                  sizes="(min-width: 1000px) 50vw - 8rem, (min-width: 768px) 60vw, calc(100vw - 6rem)"
                  src="https://cdn.sanity.io/images/13m8empt/production/4a5cd7c67c22dc3d764874582ad41b89f61fcf94-2000x2000.png?auto=format&w=300"
                  alt="Textured background"
                  aria-hidden="true"
                  role="presentation"
                  loading="lazy"
                  class="image__texture"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-lg-6">
          <div
            class="using-varo-section__steps"
            data-ui-component="using-varo-steps"
          >
            <h2
              class="headline using-varo-section__steps__headline show-for-large-up"
              data-ui-component="using-varo-step"
              data-step-id="00"
            >
              {section.headline}
            </h2>

            <div data-ui-component="using-varo-steps-wrapper">
              {#each section.list as step, index}
                <div
                  class="using-varo-section__steps__step"
                  data-ui-component="using-varo-step"
                  data-step-id={index}
                >
                  <h3 class="using-varo-section__steps__step__headline">
                    {step.headline}
                  </h3>
                  <div class="using-varo-section__steps__step__copy">
                    <PortableText value={step.body} />
                  </div>
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
@media only screen and (min-width: 768px) {
  .using-varo-section {
    height: 700vh;
    position: relative;
    width: 100%;
    position: sticky;
  }
}
@media only screen and (min-width: 1000px) {
  .using-varo-section {
    /* margin-bottom: -6vh; */
    /* height: 700vh; */
    /* margin-bottom: -9vh;
    margin-top: -6vh; */
  }
}
.using-varo-section .using-varo-section__inner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: columns;
}
@media only screen and (min-width: 768px) {
  .using-varo-section .using-varo-section__inner {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}
.using-varo-section .using-varo-section__header {
  text-align: center;
  max-width: 20rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2.25rem;
}
@media only screen and (min-width: 768px) {
  .using-varo-section .using-varo-section__header {
    max-width: 25rem;
    margin-bottom: 3.375rem;
    margin-top: -3.75rem;
  }
}
@media only screen and (min-width: 1000px) {
  .using-varo-section .using-varo-section__header {
    margin-bottom: 4.5rem;
    max-width: 32rem;
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .using-varo-section
    .using-varo-section__header
    .using-varo-section__header__headline {
    font-size: 2.25rem;
    line-height: 1.2;
  }
}
.using-varo-section .using-varo-swipe-slideshow {
  height: 100%;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
@media only screen and (min-width: 768px) {
  .using-varo-section .using-varo-swipe-slideshow {
    display: none;
  }
}
.using-varo-section .using-varo-swipe-slideshow .swiper-container {
  overflow: visible !important;
}
.using-varo-section .using-varo-swipe-slideshow .swiper-button-next,
.using-varo-section .using-varo-swipe-slideshow .swiper-button-prev {
  bottom: 50%;
  color: #0491bc;
}
.using-varo-section
  .using-varo-swipe-slideshow
  .swiper-button-next.swiper-button-disabled,
.using-varo-section
  .using-varo-swipe-slideshow
  .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}
.using-varo-section .using-varo-swipe-slideshow .swiper-button-prev {
  left: 10vw;
}
.using-varo-section .using-varo-swipe-slideshow .swiper-button-next {
  right: 10vw;
}
.using-varo-section
  .using-varo-swipe-slideshow
  .using-varo-swipe-slideshow__slide {
  background-color: red;
  height: 100%;
  width: 100%;
  opacity: 0;
}
@media only screen and (min-width: 768px) {
  .using-varo-section .using-varo-section__phone-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0 !important;
  }
}
@media only screen and (min-width: 1000px) {
  .using-varo-section .using-varo-section__phone-container {
    max-width: 48rem;
    height: 100vh;
    padding-top: 4.5rem;
  }
}
.using-varo-section .using-varo-section__phone {
  position: relative;
  max-width: 13rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 768px) {
  .using-varo-section .using-varo-section__phone {
    max-width: 17rem;
    min-width: 16rem;
    width: 32vw;
    margin-bottom: 2.25rem;
  }
}
@media only screen and (min-width: 1000px) {
  .using-varo-section .using-varo-section__phone {
    max-width: 18.5rem;
    margin-bottom: 0;
  }
}
.using-varo-section
  .using-varo-section__phone
  .using-varo-section__phone__frame {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  z-index: 2;
}
.using-varo-section
  .using-varo-section__phone
  .using-varo-section__phone__frame
  svg {
  width: 100%;
  height: 100%;
}
.using-varo-section
  .using-varo-section__phone
  .using-varo-section__phone__screens {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 88%;
  top: 1.75rem;
  height: 86%;
  overflow: hidden;
  -o-object-fit: contain;
  object-fit: contain;
  background-color: #fff;
}
@media only screen and (min-width: 768px) {
  .using-varo-section
    .using-varo-section__phone
    .using-varo-section__phone__screens {
    top: 2rem;
  }
}
@media only screen and (min-width: 1000px) {
  .using-varo-section
    .using-varo-section__phone
    .using-varo-section__phone__screens {
    top: 2.5rem;
  }
}
.using-varo-section
  .using-varo-section__phone
  .using-varo-section__phone__screens
  .using-varo-section__phone__screens__home-screen {
  display: none;
}
@media only screen and (min-width: 768px) {
  .using-varo-section
    .using-varo-section__phone
    .using-varo-section__phone__screens
    .using-varo-section__phone__screens__home-screen {
    display: flex;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
}
.using-varo-section
  .using-varo-section__phone
  .using-varo-section__phone__screens
  .using-varo-section__phone__screens__home-screen
  .screen-container {
  width: 100%;
  height: 100%;
}
.using-varo-section
  .using-varo-section__phone
  .using-varo-section__phone__screens
  .using-varo-section__phone__screens__home-screen
  .varo-logo {
  width: 5rem;
  height: 7rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.using-varo-section
  .using-varo-section__phone
  .using-varo-section__phone__screens
  .using-varo-section__phone__screens__screen
  .screen-container
  img {
  width: 100%;
}
.using-varo-section
  .using-varo-section__phone
  .using-varo-section__phone__shadow {
  position: absolute;
  background-color: rgba(5, 24, 41, 0.6);
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  margin: auto;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 47px;
  bottom: 0;
  filter: blur(10px);
  opacity: 0.2;
  z-index: 0;
  left: 0;
}
.using-varo-section .using-varo-section__phone-bg {
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  margin: auto;
  left: -10%;
  right: 0;
  width: 125%;
  top: 0;
  z-index: -1;
}
@media only screen and (min-width: 768px) {
  .using-varo-section .using-varo-section__phone-bg {
    top: -6%;
    left: -72%;
    width: 242%;
  }
}
.using-varo-section .using-varo-section__steps {
  z-index: 1;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .using-varo-section .using-varo-section__steps {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1000px) {
  .using-varo-section .using-varo-section__steps {
    height: 100vh;
    padding-top: 0;
    top: -3vw;
    max-width: 50rem;
  }
}
@media only screen and (min-width: 1000px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__headline {
    max-width: 23rem;
    margin-bottom: 4.5rem;
    font-size: 2rem;
    line-height: 1.25;
    left: 0;
    position: absolute;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1240px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__headline {
    font-size: 1.5rem;
    line-height: 1.2;
    max-width: 16rem;
  }
}
@media only screen and (min-width: 768px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__step {
    position: absolute;
    top: 0;
    max-width: 26rem;
  }
}
@media only screen and (min-width: 1000px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__step {
    max-width: 27rem;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1240px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__step {
    padding-right: 14%;
  }
}

@media only screen and (min-width: 1000px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__step {
    max-width: 26rem;
    left: 0;
    top: auto;
    bottom: auto;
    opacity: 0;
  }
}

@media only screen and (min-width: 1300px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__step {
    max-width: 29rem;
  }
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__step
    .using-varo-section__steps__step__copy {
    max-width: 29rem;
  }
}

.using-varo-section
  .using-varo-section__steps
  .using-varo-section__steps__step
  .using-varo-section__steps__step__headline {
  color: var(--primary-color);
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__step
    .using-varo-section__steps__step__headline {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1000px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__step
    .using-varo-section__steps__step__headline {
    text-align: left;
  }
}
.using-varo-section
  .using-varo-section__steps
  .using-varo-section__steps__step
  .using-varo-section__steps__step__copy {
  text-align: center;
  max-width: 70%;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 768px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__step
    .using-varo-section__steps__step__copy {
    max-width: 100%;
    text-align: center;
    margin: 0;
    left: 0;
    right: 0;
    font-size: 1rem;
    line-height: 1.5;
  }
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__step
    .using-varo-section__steps__step__copy
    p {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1000px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__step
    .using-varo-section__steps__step__copy {
    max-width: 88%;
    text-align: left;
    font-size: 1.15rem;
  }
}
.using-varo-section .using-varo-section__steps .swiper-wrapper {
  padding-bottom: 2rem;
}
.using-varo-section .using-varo-section__steps .swiper-pagination {
  bottom: -1rem;
  width: 100%;
  max-width: 16rem;
  margin-right: auto;
  margin-left: auto;
  right: 0;
}
.using-varo-section
  .using-varo-section__steps
  .swiper-pagination
  .swiper-pagination-bullet {
  height: 0.875rem;
  width: 0.875rem;
  background-color: #fff;
  opacity: 1 !important;
  position: relative;
  border: 2px solid #adadad;
  margin-right: 4%;
  bottom: 1.5rem;
}
.using-varo-section
  .using-varo-section__steps
  .swiper-pagination
  .swiper-pagination-bullet:after {
  content: " ";
  background-color: var(--primary-color);
  width: 85%;
  height: 85%;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transform: scale(0);
}
.using-varo-section
  .using-varo-section__steps
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 2px solid #0491bc;
}
.using-varo-section
  .using-varo-section__steps
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  transform: scale(1);
}
.using-varo-section .using-varo-section__scroll-menu {
  display: none;
}
@media only screen and (min-width: 768px) {
  .using-varo-section .using-varo-section__scroll-menu {
    position: absolute;
    height: 85vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    margin: auto;
    right: -49vw;
  }
}
@media only screen and (min-width: 1000px) {
  .using-varo-section .using-varo-section__scroll-menu {
    right: -99vw;
    height: 100vh;
    bottom: 0;
  }
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu-wrapper {
  transform: translateZ(0) scaleX(1) rotateX(0) rotateY(0) rotate(0)
    skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: 1vw;
  position: relative;
  height: 67%;
  width: 13%;
}
@media only screen and (min-width: 1000px) {
  .using-varo-section
    .using-varo-section__scroll-menu
    .using-varo-section__scroll-menu-wrapper {
    right: 2vw;
  }
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__scroll-line {
  height: 100%;
  width: 2px;
  position: absolute;
  background-color: #dedede;
  left: 0;
  right: 0;
  margin: auto;
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__scroll-line__progress {
  background-color: #0491bc;
  width: 2px;
  height: 0%;
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__point {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 100%;
  position: relative;
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__point.is-active
  .using-varo-section__scroll-menu__point__bullet:after {
  background-color: var(--primary-color);
  width: 1.1rem;
  height: 1.1rem;
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__point.is-active
  .using-varo-section__scroll-menu__point__bullet:before {
  width: 1.7rem;
  height: 1.7rem;
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__point.is-active
  .using-varo-section__scroll-menu__point__text {
  color: var(--primary-color);
  opacity: 1;
  transform: translateX(-0.5rem);
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__point.is-checked
  .using-varo-section__scroll-menu__point__text {
  color: #494949;
  opacity: 0.2;
  transform: translateX(0);
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__point.is-checked
  .using-varo-section__scroll-menu__point__bullet:after {
  background-color: #0491bc;
  width: 0.4rem;
  height: 0.4rem;
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__point.is-checked
  .using-varo-section__scroll-menu__point__bullet:before {
  background-color: #0491bc;
  width: 0.8rem;
  height: 0.8rem;
  border-color: #0491bc;
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__point__text {
  width: 124%;
  white-space: nowrap;
  right: 6rem;
  width: 200%;
  text-align: right;
  position: absolute;
  color: #494949;
  opacity: 0.6;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;
  right: 4.5rem;
  font-weight: 700;
  font-family: "Be Vietnam Pro", Helvetica, sans-serif;
  font-display: fallback;
  font-size: 0.7rem;
  line-height: 1.5;
}
@media only screen and (min-width: 1000px) {
  .using-varo-section
    .using-varo-section__scroll-menu
    .using-varo-section__scroll-menu__point__text {
    right: 7.5vw;
    font-size: 0.9rem;
    line-height: 1.5;
  }
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__point__bullet {
  background: 0 0;
  height: 1rem;
  width: 2rem;
  border-radius: 100%;
  position: relative;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__point__bullet:before {
  content: " ";
  background: #fff;
  border: 2px solid #00a1f4;
  height: 0.8rem;
  width: 0.8rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  border-radius: 100%;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.using-varo-section
  .using-varo-section__scroll-menu
  .using-varo-section__scroll-menu__point__bullet:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #fff;
  border-radius: 100%;
  width: 0.4rem;
  height: 0.4rem;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media only screen and (min-width: 1000px) {
  .using-varo-section
    .using-varo-section__steps
    .using-varo-section__steps__headline {
    margin-bottom: 1rem;
  }
}

.using-varo-section
  .using-varo-section__phone
  .using-varo-section__phone__screens {
  margin: auto;
  width: 74%;
  left: -5%;
}

.using-varo-section .using-varo-section__phone {
  max-width: 25rem;
}
.using-varo-section .using-varo-section__steps :global(.swiper-pagination) {
  left: 0;
}

.using-varo-section__steps {
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 768px) {
  [data-ui-component="using-varo-steps-wrapper"] {
    display: flex;
    justify-content: center;
  }
}

.using-varo-section
  .using-varo-section__phone
  .using-varo-section__phone__frame
  svg {
  height: 25rem;
}

@media screen and (min-width: 768px) {
  .using-varo-section
    .using-varo-section__phone
    .using-varo-section__phone__frame
    svg {
    height: 32rem;
  }
}

@media screen and (min-width: 1000px) {
  .using-varo-section
    .using-varo-section__phone
    .using-varo-section__phone__frame
    svg {
    height: 36rem;
  }
}

.using-varo-section
  .using-varo-section__phone
  .using-varo-section__phone__screens {
  margin: auto;
  width: 74%;
  top: 1.75rem;
  height: 80%;
  max-width: 11rem;
  overflow: hidden;
  -o-object-fit: contain;
  object-fit: contain;
  background-color: #fff;
  left: -3%;
}

@media screen and (min-width: 768px) {
  .using-varo-section
    .using-varo-section__phone
    .using-varo-section__phone__screens {
    width: 85%;
    left: -5.5%;
    top: 2.5rem;
    max-width: 15rem;
  }
}

@media screen and (min-width: 1000px) {
  .using-varo-section
    .using-varo-section__phone
    .using-varo-section__phone__screens {
    top: 2.8rem;
    left: -4%;
    width: 87%;
    right: 0;
    height: 100%;
    margin: auto;
    max-width: 16rem;
  }
}

@media only screen and (min-width: 768px) {
  .using-varo-section
    .using-varo-section__phone
    .using-varo-section__phone__screens
    .using-varo-section__phone__screens__home-screen {
    display: flex;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 82%;
    background-color: #fff;
  }
}

.image__texture {
  top: -2rem;
  margin: auto;
  position: absolute;
  bottom: 0;
  width: 95%;
  left: 0;
  right: 0;
}

@media only screen and (min-width: 1000px) {
  .image__texture {
    top: 0;
    width: 80%;
  }
}

.using-varo-section__scroll-menu__point__text {
  font-weight: normal;
}
</style>
