<script>
import { PortableText } from '@portabletext/svelte';
import { onMount } from 'svelte';
export let section;
let gsap;
let TweenMax;
let Power1;
let Power2;
let TimelineMax;

onMount(async () => {
  const module = await import('gsap');
  gsap = module.default;

  const tmxModule = await import('gsap');
  TweenMax = module.TweenMax;

  const Power2Module = await import('gsap');
  Power2 = module.Power2;
  Power1 = module.Power1;

  const tlmMdoule = await import('gsap');
  TimelineMax = tlmMdoule.TimelineMax;

  var heroHeaderAnims = {
    animation: null,
    breakpoint: null,
    controller: null,
    desktopAnimTimeline: null,
    selectors: {
      screen1: '[data-ui-component="screen-1"]',
      screen2: '[data-ui-component="screen-2"]',
      screen3: '[data-ui-component="screen-3"]',
      envelope: '[data-ui-component="envelope"]',
      animationContainer: '[data-ui-component="hero-header-anim-container"]',
    },
    initDesktopAnim: function () {
      var that = this;

      var globalEase = Power1.easeOut;

      var options = { repeatDelay: 1, repeat: -1, yoyo: false };

      that.desktopAnimTimeline = new TimelineMax(options)
        .fromTo(
          that.selectors.screen3,
          0.5,
          { opacity: 0, y: '0', x: '20px', rotation: '0' },
          { opacity: 1, y: '0', x: '0', rotationY: '0', ease: globalEase },
          '+=0.1'
        )
        .fromTo(
          that.selectors.screen2,
          0.5,
          { opacity: 0, y: '0', x: '20px', rotation: '0' },
          { opacity: 1, y: '0', x: '0', rotationY: '0', ease: globalEase },
          '-=0.1'
        )
        .fromTo(
          that.selectors.screen1,
          0.5,
          { opacity: 0, y: '0', x: '20px', rotation: '0' },
          { opacity: 1, y: '0', x: '0', rotationY: '0', ease: globalEase },
          '-=0.1'
        )
        .to(
          that.selectors.screen1,
          0.5,
          {
            opacity: 0,
            y: '120px',
            x: '0',
            rotationY: '2deg',
            scale: 0.6,
            ease: Power2.easeInOut,
          },
          '+=.8'
        )
        .to(
          that.selectors.screen2,
          0.5,
          {
            opacity: 0,
            y: '0',
            x: '0',
            rotationY: '2deg',
            scale: 0.6,
            ease: Power2.easeInOut,
          },
          '-=0.5'
        )
        .to(
          that.selectors.screen3,
          0.5,
          {
            opacity: 0,
            y: '-120px',
            x: '0',
            rotationY: '2deg',
            scale: 0.6,
            ease: Power2.easeInOut,
          },
          '-=0.5'
        )
        .fromTo(
          that.selectors.envelope,
          0.6,
          {
            opacity: 0,
            y: '20px',
            x: '0',
            rotation: '0',
            scale: 0.5,
            rotationY: '0',
          },
          {
            opacity: 1,
            y: '0',
            x: '0',
            rotation: '0',
            scale: 1,
            rotationY: '0',
            ease: Power2.easeInOut,
          },
          '-=0.4'
        )
        .to(
          that.selectors.envelope,
          0.6,
          { css: { transform: 'rotateY(180deg)' }, ease: Power2.easeInOut },
          '+=0.5'
        )
        .to(
          that.selectors.animationContainer,
          0.9,
          {
            css: {
              opacity: 0,
              transform:
                ' translateY(-120px)  translateX(-250px) rotateZ(10deg) scale(.8)',
            },
            ease: Power2.easeInOut,
          },
          '-=0.1'
        );

      that.desktopAnimTimeline.play();
    },
    init: function (pWindowWidth) {
      const that = this;

      var currentBreakpoint = window
        .getComputedStyle(document.querySelector('body'), ':before')
        .getPropertyValue('content')
        .replace(/"/g, '');

      if (currentBreakpoint == 'medium-up' || currentBreakpoint == 'large-up') {
        that.initDesktopAnim();
      } else {
        return false;
      }
    },
  };

  heroHeaderAnims.init(window.innerWidth);
});
</script>

<section class="hero-header">
  <div class="hero-header__inner">
    <div class="container-fluid">
      <div class="row  reverse-md">
        <div class="col-sm-12   col-md-5 col-lg-5">
          <div class="hero-header__illustration">
            <div class="hero-header__illustration__foreground ">
              <img
                loading="eager"
                src="https://cdn.sanity.io/images/13m8empt/production/c6e74218b92e7a63f36302da5d26f81d2fee9e31-1900x1254.png?auto=format&w=400 400w"
                aria-hidden="true"
                class="hero-header__illustration__foreground__matte "
                role="presentation"
                alt="Foreground cover"
                srcset="
                 https://cdn.sanity.io/images/13m8empt/production/c6e74218b92e7a63f36302da5d26f81d2fee9e31-1900x1254.png?auto=format&w=1200 1200w,
                 https://cdn.sanity.io/images/13m8empt/production/c6e74218b92e7a63f36302da5d26f81d2fee9e31-1900x1254.png?auto=format&w=1000 1000w,
                 https://cdn.sanity.io/images/13m8empt/production/c6e74218b92e7a63f36302da5d26f81d2fee9e31-1900x1254.png?auto=format&w=800 800w,
                 https://cdn.sanity.io/images/13m8empt/production/c6e74218b92e7a63f36302da5d26f81d2fee9e31-1900x1254.png?auto=format&w=600 600w,
                 https://cdn.sanity.io/images/13m8empt/production/c6e74218b92e7a63f36302da5d26f81d2fee9e31-1900x1254.png?auto=format&w=400 400w,
                 https://cdn.sanity.io/images/13m8empt/production/c6e74218b92e7a63f36302da5d26f81d2fee9e31-1900x1254.png?auto=format&w=300 300w"
                sizes="(min-width: 1500px) 1300px, (min-width: 1000px) 1000px, (min-width: 768px) 80vw, 90vw"
              />

              <picture>
                <source
                  srcset="https://cdn.sanity.io/images/13m8empt/production/de8b72e6e481a4418582abf89c8dfab70e1cb6e0-1412x1781.svg"
                  media="(min-width: 768px)"
                />
                <img
                  loading="eager"
                  src="https://cdn.sanity.io/images/13m8empt/production/06d24a702270d4b8084759b4256807e9aa02bf36-1412x1839.svg"
                  aria-hidden="true"
                  class="hero-header__illustration__foreground__medical-worker "
                  role="presentation"
                  alt="Illustration of a medical worker using the Varo app while looking over the status of a cold-chain fridge inside of a lab."
                />
              </picture>

              <div class="screen-perspective">
                <div
                  class="screen-container"
                  data-ui-component="hero-header-anim-container"
                >
                  <div class="screen" data-ui-component="screen-1">
                    <picture>
                      <source
                        srcset="https://cdn.sanity.io/images/13m8empt/production/05e10316d6327991bf3256b7ae6c99a32fdd7710-289x288.svg"
                        media="(min-width: 768px)"
                      />
                      <img
                        loading="lazy"
                        src="https://cdn.sanity.io/images/13m8empt/production/4848a09d2489a6be5c15bdaf6f44c1f9e0d608ee-1x1.gif"
                        alt="Stylized illustration of a cold chain fridge photo on the Varo app."
                        aria-hidden="true"
                        role="presentation"
                      />
                    </picture>
                  </div>

                  <div class="screen" data-ui-component="screen-2">
                    <picture>
                      <source
                        srcset="https://cdn.sanity.io/images/13m8empt/production/bd3c836a895e3af32502d501654cad1a8119f113-289x288.svg"
                        media="(min-width: 768px)"
                      />
                      <img
                        loading="lazy"
                        src="https://cdn.sanity.io/images/13m8empt/production/4848a09d2489a6be5c15bdaf6f44c1f9e0d608ee-1x1.gif"
                        alt="Stylized illustration of a cold chain fridge's location on the Varo app."
                        aria-hidden="true"
                        role="presentation"
                      />
                    </picture>
                  </div>

                  <div class="screen" data-ui-component="screen-3">
                    <picture>
                      <source
                        srcset="https://cdn.sanity.io/images/13m8empt/production/5e113e34c2beade54ef2d08b52fc93c17fb4cc2d-288x288.svg"
                        media="(min-width: 768px)"
                      />
                      <img
                        loading="lazy"
                        src="https://cdn.sanity.io/images/13m8empt/production/4848a09d2489a6be5c15bdaf6f44c1f9e0d608ee-1x1.gif"
                        alt="Stylized illustration of a cold chain fridge's temperature and service history on the Varo app."
                        aria-hidden="true"
                        role="presentation"
                      />
                    </picture>
                  </div>

                  <div class="envelope" data-ui-component="envelope">
                    <img
                      loading="lazy"
                      src="/hero_envelope_front.svg"
                      alt="Illustration of Varo report packaged as an envelope"
                      class=""
                    />
                    <img
                      loading="lazy"
                      src="/hero_envelope_back.svg"
                      alt="Stylized email envelope illustration"
                      class=" back"
                      aria-hidden="true"
                      role="presentation"
                    />
                  </div>
                </div>
              </div>
            </div>

            <img
              loading="eager"
              alt="Background cover"
              src="https://cdn.sanity.io/images/13m8empt/production/3cbca9fc9566ad1a5df7ee1805c05b31572d74a6-1900x1254.jpg?auto=format&w=300 300w"
              aria-hidden="true"
              class="hero-header__illustration__background "
              role="presentation"
              srcset="
              https://cdn.sanity.io/images/13m8empt/production/3cbca9fc9566ad1a5df7ee1805c05b31572d74a6-1900x1254.jpg?auto=format&w=1400 1400w,
                https://cdn.sanity.io/images/13m8empt/production/3cbca9fc9566ad1a5df7ee1805c05b31572d74a6-1900x1254.jpg?auto=format&w=1200 1200w,
                https://cdn.sanity.io/images/13m8empt/production/3cbca9fc9566ad1a5df7ee1805c05b31572d74a6-1900x1254.jpg?auto=format&w=1000 1000w,
                https://cdn.sanity.io/images/13m8empt/production/3cbca9fc9566ad1a5df7ee1805c05b31572d74a6-1900x1254.jpg?auto=format&w=800 800w,
                https://cdn.sanity.io/images/13m8empt/production/3cbca9fc9566ad1a5df7ee1805c05b31572d74a6-1900x1254.jpg?auto=format&w=600 600w,
                https://cdn.sanity.io/images/13m8empt/production/3cbca9fc9566ad1a5df7ee1805c05b31572d74a6-1900x1254.jpg?auto=format&w=400 400w,
                https://cdn.sanity.io/images/13m8empt/production/3cbca9fc9566ad1a5df7ee1805c05b31572d74a6-1900x1254.jpg?auto=format&w=300 300w"
              sizes="(min-width: 1500px) 1300px, (min-width: 1000px) 1000px, (min-width: 768px) 80vw, 90vw"
            />
          </div>
        </div>

        <div class="col-sm-12   col-md-7 col-lg-7">
          <div class="hero-header__copy">
            <h1 class="headline  hero-header__headline">
              {section.headline}
            </h1>
            <div class="hero-header__body">
              <PortableText value={section.body}  components={{}} />
            </div>

            <a
              href="https://play.google.com/store/apps/details?id=com.intven.varo"
              title="Download Varo on the Google Play store"
              target="_blank"
              rel="external"
              class=" hero-header__button"
            >
              <svg>
                <title>Get it on Google Play</title>
                <use xlink:href="#google-play-button" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
.hero-header {
  overflow: hidden;
  z-index: 0;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .hero-header {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 45rem;
    min-height: 45rem;
  }
}
@media only screen and (min-width: 1000px) {
  .hero-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2.25rem;
    height: 100vh;
    max-height: 58rem;
    min-height: 50rem;
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 1681px) {
  .hero-header {
    max-height: 62rem;
    min-height: 56rem;
  }
}
@media only screen and (min-width: 2000px) {
  .hero-header {
    max-height: 65rem;
    min-height: 65rem;
    max-width: 100rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.hero-header .hero-header__inner {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .hero-header .hero-header__inner {
    max-width: 53.75rem;
  }
}
@media only screen and (min-width: 1000px) {
  .hero-header .hero-header__inner {
    max-width: 74rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}
.hero-header .hero-header__copy {
  position: relative;
  z-index: 2;
  max-width: 24rem;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 768px) {
  .hero-header .hero-header__copy {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 80%;
  }
}
@media only screen and (min-width: 1000px) {
  .hero-header .hero-header__copy {
    margin-right: 0;
    margin-left: 0;
    bottom: 6rem;
    max-width: 35rem;
    width: 100%;
    justify-content: center;
  }
}
.hero-header .hero-header__illustration {
  padding-bottom: 100%;
  position: relative;
  z-index: 0;
}
@media only screen and (min-width: 768px) {
  .hero-header .hero-header__illustration {
    position: inherit;
    padding-bottom: 100%;
  }
}
.hero-header .hero-header__illustration .hero-header__illustration__background,
.hero-header .hero-header__illustration .hero-header__illustration__foreground {
  width: 140%;
  max-width: 200%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  bottom: 0;
}
@media only screen and (min-width: 768px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__background,
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__foreground {
    top: 6rem;
    position: absolute;
    width: 140%;
    max-width: 65rem;
    min-width: 65rem;
  }
}
.hero-header .hero-header__illustration .hero-header__illustration__foreground {
  right: -26%;
  top: 12vw;
}
@media only screen and (min-width: 768px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__foreground {
    right: -21%;
    top: 3rem;
  }
}
@media only screen and (min-width: 1000px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__foreground {
    right: -5%;
    top: 3rem;
    min-width: 70rem;
    max-width: 75rem;
  }
}
@media only screen and (min-width: 1681px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__foreground {
    right: 0;
    max-width: 78rem;
  }
}
.hero-header
  .hero-header__illustration
  .hero-header__illustration__foreground
  .hero-header__illustration__foreground__matte {
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 1rem;
  right: 5%;
}
@media only screen and (min-width: 768px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__foreground
    .hero-header__illustration__foreground__matte {
    top: 0;
  }
}
@media only screen and (min-width: 1000px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__foreground
    .hero-header__illustration__foreground__matte {
    right: 0;
  }
}
@media only screen and (min-width: 2000px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__foreground
    .hero-header__illustration__foreground__matte {
    top: 1rem;
  }
}
.hero-header
  .hero-header__illustration
  .hero-header__illustration__foreground
  .hero-header__illustration__foreground__medical-worker {
  width: 100%;
  position: absolute;
  top: 2.5rem;
  height: 100%;
  right: 8%;
  z-index: 0;
}
@media only screen and (min-width: 768px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__foreground
    .hero-header__illustration__foreground__medical-worker {
    width: 39%;
    top: 5.5rem;
    height: 100%;
    right: 20%;
    z-index: 0;
  }
}
@media only screen and (min-width: 1000px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__foreground
    .hero-header__illustration__foreground__medical-worker {
    width: 42%;
    right: 10%;
    top: 1.5rem;
  }
}
@media screen and (min-width: 1100px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__foreground
    .hero-header__illustration__foreground__medical-worker {
    right: 15%;
  }
}
@media screen and (min-width: 1300px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__foreground
    .hero-header__illustration__foreground__medical-worker {
    right: 25%;
  }
}
.hero-header .hero-header__illustration .hero-header__illustration__background {
  z-index: -1;
  top: 4rem;
  right: -19%;
}
@media only screen and (min-width: 768px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__background {
    top: 4rem;
    right: -20%;
  }
}
@media only screen and (min-width: 1000px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__background {
    top: 4rem;
    right: -4%;
    min-width: 70rem;
    width: 80vw;
  }
}
@media only screen and (min-width: 1681px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__background {
    right: 0;
    top: 1rem;
    max-width: 80rem;
    width: 93vw;
  }
}
@media only screen and (min-width: 2000px) {
  .hero-header
    .hero-header__illustration
    .hero-header__illustration__background {
    right: -1%;
    top: 1rem;
    max-width: 80rem;
  }
}
.hero-header .hero-header__illustration .screen-perspective {
  perspective: 1300px;
  width: 64vw;
  position: absolute;
  right: 2vw;
  max-width: 55rem;
  height: 0;
  min-width: 48rem;
  z-index: 4;
  display: none;
  top: 13.5rem;
  right: -20%;
  left: auto;
}
@media only screen and (min-width: 768px) {
  .hero-header .hero-header__illustration .screen-perspective {
    display: block;
  }
}
@media only screen and (min-width: 1000px) {
  .hero-header .hero-header__illustration .screen-perspective {
    right: -27%;
    max-width: 51rem;
    top: 12.5rem;
  }
}
@media screen and (min-width: 1100px) {
  .hero-header .hero-header__illustration .screen-perspective {
    right: -22%;
  }
}
@media screen and (min-width: 1300px) {
  .hero-header .hero-header__illustration .screen-perspective {
    right: -12%;
  }
}
@media only screen and (min-width: 1681px) {
  .hero-header .hero-header__illustration .screen-perspective {
    right: -10%;
  }
}
.hero-header .hero-header__illustration .screen-perspective .screen {
  width: 5rem;
  height: 5rem;
  -webkit-animation: enter-screen 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  animation: enter-screen 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  z-index: 0;
}
@media only screen and (min-width: 1000px) {
  .hero-header .hero-header__illustration .screen-perspective .screen {
    width: 5.5rem;
    height: 5.5rem;
  }
}
@media only screen and (min-width: 1681px) {
  .hero-header .hero-header__illustration .screen-perspective .screen {
    width: 6rem;
    height: 6rem;
  }
}
.hero-header .hero-header__illustration .screen-perspective .screen img {
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hero-header .hero-header__illustration .screen-perspective .screen-container {
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 100%;
  transform-origin: center;
}
@media only screen and (min-width: 768px) {
  .hero-header
    .hero-header__illustration
    .screen-perspective
    .screen-container {
    right: -2.5rem;
    top: -2.5rem;
  }
}
@media only screen and (min-width: 1000px) {
  .hero-header
    .hero-header__illustration
    .screen-perspective
    .screen-container {
    right: 2.5rem;
    top: -0.5rem;
  }
}
.hero-header
  .hero-header__illustration
  .screen-perspective
  .screen-container
  .screen {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-bottom: auto;
  z-index: 0;
}
.hero-header
  .hero-header__illustration
  .screen-perspective
  .screen-container
  .screen:first-child {
  top: -4.5rem;
  position: absolute;
  left: 3vw;
}
@media only screen and (min-width: 1681px) {
  .hero-header
    .hero-header__illustration
    .screen-perspective
    .screen-container
    .screen:first-child {
    top: -4.75rem;
  }
}
.hero-header
  .hero-header__illustration
  .screen-perspective
  .screen-container
  .screen:nth-child(2) {
  right: auto;
  left: 3vw;
  top: 1.5rem;
  bottom: 9.5vw;
  position: absolute;
}
@media only screen and (min-width: 1681px) {
  .hero-header
    .hero-header__illustration
    .screen-perspective
    .screen-container
    .screen:nth-child(2) {
    top: 2rem;
  }
}
.hero-header
  .hero-header__illustration
  .screen-perspective
  .screen-container
  .screen:nth-child(3) {
  position: absolute;
  right: auto;
  top: 7.5rem;
  bottom: 1vh;
  left: 3vw;
}
@media only screen and (min-width: 1681px) {
  .hero-header
    .hero-header__illustration
    .screen-perspective
    .screen-container
    .screen:nth-child(3) {
    top: 8.75rem;
  }
}
.hero-header
  .hero-header__illustration
  .screen-perspective
  .screen-container
  .envelope {
  position: absolute;
  width: 6rem;
  min-height: 4.5rem;
  top: 1.75rem;
  left: 2.5%;
  z-index: 1;
  transform-style: preserve-3d;
  top: 2.5rem;
  opacity: 0;
}
@media only screen and (min-width: 1000px) {
  .hero-header
    .hero-header__illustration
    .screen-perspective
    .screen-container
    .envelope {
    min-width: 7rem;
    min-height: 5rem;
    left: 4%;
  }
}
.hero-header
  .hero-header__illustration
  .screen-perspective
  .screen-container
  .envelope
  img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
}
.hero-header
  .hero-header__illustration
  .screen-perspective
  .screen-container
  .envelope
  .back {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  width: 100%;
  height: 100%;
}
.hero-header .hero-header__button svg {
  width: 10rem;
  display: block;
  height: 3rem;
}
.hero-header .hero-header__headline {
  max-width: 20rem;
}
@media only screen and (min-width: 768px) {
  .hero-header .hero-header__headline {
    max-width: 21rem;
    width: 100%;
    font-size: 2rem;
    line-height: 1.25;
  }
}
@media only screen and (min-width: 1000px) {
  .hero-header .hero-header__headline {
    max-width: 35rem;
    line-height: 1.25;
    font-size: 2.15rem;
    line-height: 1.2;
  }
}
.hero-header .hero-header__body {
  max-width: 23.25rem;
  font-size: 1rem;
  line-height: 1.5;
}
@media only screen and (min-width: 768px) {
  .hero-header .hero-header__body {
    max-width: 27rem;
  }
}
@media only screen and (min-width: 1000px) {
  .hero-header .hero-header__body {
    font-size: 1.15rem;
    line-height: 1.5;
    max-width: 29rem;
  }
}
</style>
